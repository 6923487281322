<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-card class="mt-1">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4><i class="fa fa-users" /> แก้ไขข้อมูลลูกค้า</h4>
            </div>
          </div>
          <hr class="m-0 p-0">
          <!-- <h4><i class="fas fa-user-edit" /> แก้ไขข้อมูลลูกค้า</h4> -->
          <b-row class="mt-1">
            <b-col md="6">
              <b-form-group>
                <label>ชื่อ <small class="text-danger">*</small></label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.name"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>นามสกุล <small class="text-danger">*</small></label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.surname"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>เบอร์โทรศัพท์</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.tel"
                  type="text"
                  disabled
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>รหัสผ่าน</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.passwords"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>ธนาคาร</label>
                <v-select
                  v-model="userDetails.bank"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="(name) => name.bankcode"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>เลขที่บัญชี</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.acc_no"
                  type="number"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>เบอร์ Truemoney Wallet</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.trueaccno"
                  type="number"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group>
                <label>สถานะลูกค้า</label>
                <b-form-select
                  v-model="userDetails.status"
                  :options="options"
                />
              </b-form-group>
            </b-col> -->

            <b-col md="6">
              <b-form-group>
                <label>Line ID</label>
                <b-form-input
                  id="floating-label2"
                  v-model="userDetails.line_id"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- <b-col md="12">
              <b-form-group>
                <label>สถานะ VIP</label>
                <b-form-checkbox
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <hr>
            </b-col> -->

            <!-- <b-col
              md="12"
              class="card-warning"
            >
              <div class="mt-1">
                <b-form-group>
                  <label>ตั้งให้เป็นเซียน</label>
                  <b-form-checkbox
                    v-model="userDetails.zean"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group> -->

            <!-- <b-form-group>
                  <label>เปิดแสดงยอดฝาก-ถอน (สำหรับเซียน)</label>

                  <b-form-checkbox
                    v-model="vip"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group> -->

            <!-- <b-form-group>
                  <label>% ที่ให้(สำหรับเซียน) (หากกำหนดตรงนี้เป็น 0
                    จะใช้ค่าที่กำหนดไว้ในระบบ)</label>

                  <b-form-input
                    id="percent"
                    v-model="userDetails.zean_percent"
                    type="number"
                    placeholder=""
                  />
                </b-form-group>
              </div>
            </b-col> -->
            <b-col
              md="12"
              class="mt-1"
            >
              <b-form-group>
                <b-button
                  variant="gradient-dark"
                  block
                  @click="update_member()"
                >
                  <i class="fas fa-save" />
                  บันทึกข้อมูล
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3">
        <div class="card-cradit white mb-1 mt-1 mr-2">
          <div class="p-1">
            <span>เครดิตตงเหลือ</span>
            <h3>{{ balance }}</h3>
          </div>
          <div class="card-b d-flex justify-content-between">
            <small>เลขที่บัญชี : {{ userDetails.acc_no }}</small>
            <!-- <small>เครดิตสมาชิก {{ balance }}</small> -->
          </div>
        </div>
        <div class="mb-1 mt-1 mr-2">
          <b-button
            variant="gradient-info"
            block
            @click="withdraws(userDetails,balance)"
          >
            <!-- <i class="fas fa-hand-holding-usd"></i> -->
            <i class="fas fa-hand-holding-usd" />
            ถอนเงิน
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div>
        <b-row class="match-height">
          <b-col md="6">
            <b-card no-body>
              <b-form-group class="p-1">
                <h3 id="deposit">
                  ประวัติการฝาก
                </h3>
              </b-form-group>
              <b-table
                striped
                hover
                responsive
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(picture)="data">
                  <b-avatar
                    size="lg"
                    :src="data.item.picture"
                  />
                </template>
                <template #cell(ip)="data">
                  <div class="text-nowrap">
                    <a
                      :href="`http://${data.item.ip}`"
                      target="_blank"
                    >{{
                      data.item.ip
                    }}</a>
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit & Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card no-body>
              <b-form-group class="mr-1 mb-md-0 p-2">
                <h3 id="withdraw">
                  รายการถอน
                </h3>
              </b-form-group>
              <b-table
                striped
                hover
                responsive
                show-empty
                class="position-relative items-center"
                :per-page="perPage"
                :current-page="currentPage"
                :items="items2"
                :fields="fields2"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #empty="scope">
                  <div class="p-2 text-center">
                    <img
                      src="/box.png"
                      alt="empty"
                      height="40px"
                    > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
                  </div>
                </template>

                <template #cell(picture)="data">
                  <b-avatar
                    size="lg"
                    :src="data.item.picture"
                  />
                </template>
                <template #cell(ip)="data">
                  <div class="text-nowrap">
                    <a
                      :href="`http://${data.item.ip}`"
                      target="_blank"
                    >{{
                      data.item.ip
                    }}</a>
                  </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-preview-icon`"
                      icon="FileTextIcon"
                      size="16"
                      class="mx-1"
                      @click="
                        $router.push({
                          name: 'dashboard',
                          params: { id: data.item.id },
                        })
                      "
                    />
                    <b-tooltip
                      title="History Deposit &amp; Withdraw"
                      :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                    <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editData(data.item)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
                  </div>
                </template>
              </b-table>

              <b-card-body
                class="d-flex justify-content-between flex-wrap pt-0"
              >
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
              <b-modal
                id="modal-1"
                title="Add Class"
                ok-only
                ok-title="Save"
              >
                <b-form-group
                  label="Class"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CheckSquareIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder=""
                    />
                  </b-input-group>
                </b-form-group>
              </b-modal>
            </b-card>
          </b-col>
        </b-row>
        <b-modal
          id="modal-success"
          hide-footer
          modal-class="modal-dark"
          size="lg"
          centered
          title="ประวัติการรับโปรโมชั่น"
        >
          <b-card-text class="p-0">
            <b-table
              striped
              hover
              responsive
              show-empty
              class="position-relative items-center"
              :per-page="perPage"
              :current-page="currentPage"
              :items="itemspro"
              :fields="fieldspro"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(picture)="data">
                <b-avatar
                  size="lg"
                  :src="data.item.picture"
                />
              </template>
              <template #cell(ip)="data">
                <div class="text-nowrap">
                  <a
                    :href="`http://${data.item.ip}`"
                    target="_blank"
                  >{{
                    data.item.ip
                  }}</a>
                </div>
              </template>
              <template #empty="scope">
                <div class="p-2 text-center">
                  <img
                    src="/box.png"
                    alt="empty"
                    height="40px"
                  > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`invoice-row-${data.item.id}-preview-icon`"
                    icon="FileTextIcon"
                    size="16"
                    class="mx-1"
                    @click="
                      $router.push({
                        name: 'dashboard',
                        params: { id: data.item.id },
                      })
                    "
                  />
                  <b-tooltip
                    title="History Deposit &amp; Withdraw"
                    :target="`invoice-row-${data.item.id}-preview-icon`"
                  />
                </div>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card-text>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BInputGroupAppend,
  // BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    // BInputGroupAppend,
    // BFormCheckbox,

    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // StatisticCardWithAreaChart,
    // VueApexCharts,
    BOverlay,
    BIconController,
    vSelect,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      show: false,
      rangeDate: null,
      balance: 0.0,
      today: '',
      perPage: 10,
      pageOptions: [10, 50, 100, 200, 500],
      totalRows: 1,
      totalRowspro: 1,
      totalRows2: 1,
      totalRowsGame: 1,
      currentPage: 1,
      perPageHis: 10,
      currentPageHis: 1,
      // withamount: null,
      // depamount: null,
      total: null,
      totalpro: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'amount', label: 'amount' },
        { key: 'bonus', label: 'bonus' },
        { key: 'balance', label: 'balance' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      itemsGame: [],
      /* eslint-disable global-require */
      items: [],
      fields2: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'amount', label: 'amount' },
        { key: 'balance', label: 'balance' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      fieldspro: [
        { key: 'index', label: 'no.' },
        { key: 'promotion', label: 'promotion' },
        { key: 'turn', label: 'turn' },
        { key: 'userturn', label: 'userturn' },
        { key: 'turnover', label: 'turnover' },
        { key: 'difturn', label: 'difturn' },
        { key: 'status', label: 'status' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      fieldsGame: [
        { key: 'index', label: 'no.' },
        { key: 'provider', label: 'provider' },
        { key: 'stake_count', label: 'stake_count' },
        { key: 'turnover', label: 'turnover' },
        { key: 'valid_amount', label: 'valid_amount' },
        { key: 'winloss', label: 'winloss' },
        { key: 'total', label: 'total' },
      ],
      items2: [],
      itemspro: [],
      search_userID: null,
      userDetails: [],
      userslist: [],
      addNewDataSidebar: false,
      depositSeries: [
        {
          name: 'deposit',
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: 'withdraw',
          data: [],
        },
      ],
      turn: '',
      turnhis: '',
      options: [
        { value: 1, text: 'Active: เข้าเล่นได้ปกติ' },
        { value: 2, text: 'Locked: เข้าสู่ล็อบบี้ไม่ได้ เข้าเล่นไม่ได้' },
        { value: 0, text: 'Suspended: เข้าสู่ล็อบบี้ได้ แต่เข้าเล่นไม่ได้' },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.getDepWit()
    this.Getuserdetail()
    this.Get_bank()
    this.Get_userlists()
    this.getbalance()
  },
  methods: {
    withdraws(User, balance) {
      if (balance <= 0) {
        this.SwalError('เครดิตของท่านเหลือ 0 บาท')
        return
      }
      this.$swal({
        title: 'ยืนยันที่จะถอนเครดิต ?',
        text: `จำนวน ${balance} บาท หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            username: User.username,
            amount: balance,
            uid: User.id,
            wto: 'acc',
          }
          this.$http
            .post('https://uatapi.sabaideelotto.com/api/lottery/withdraw/store', obj)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.getbalance()
              this.getDepWit()
              this.Success('ถอนเครดิตสำเร็จ')
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกการถอนเครดิต !', 'error')
        }
      })
    },
    getbalance() {
      this.show = true
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/zean/balance/getbalanceuser')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.balance = response.data.balance.toFixed(2)
        })
        .catch(error => console.log(error))
    },
    getHistory() {
      // console.log(this.rangeDate)
      const rangArray = this.rangeDate.split(' to ')
      // eslint-disable-next-line no-unused-vars
      const start = rangArray[0]
      // eslint-disable-next-line no-unused-vars
      const end = rangArray[1]
      // console.log(start)
      // console.log(end)
    },
    gethistory() {
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]
      const obj = {
        username: this.$route.params.id,
        start,
        end,
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/zean/history/play', obj)
        .then(response => {
          // console.log(response.data.data)
          this.listdata = response.data.data
          this.onFilteredHis(response.data.data)
        })
        .catch(error => console.log(error))
    },
    onFilteredHis(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.itemsGame = filteredItems
      this.totalRowsGame = filteredItems.total
    },
    changeUserEdit() {
      // this.getPro()
      this.getDepWit()
      this.Getuserdetail()
      this.Get_bank()
      this.Get_userlists()
      // this.gethistory()
      this.search_userID = null
    },
    Get_bank() {
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/zean/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    Get_userlists() {
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/zean/users/list')
        .then(response => {
          this.userslist = response.data
        })
        .catch(error => console.log(error))
    },
    update_member() {
      if (this.userDetails.zean === true) {
        this.userDetails.zean = 1
      } else {
        this.userDetails.zean = 0
      }
      const params = {
        name: this.userDetails.name,
        password: this.userDetails.passwords,
        surname: this.userDetails.surname,
        bank: this.userDetails.bank,
        acc_no: this.userDetails.acc_no,
        trueaccno: this.userDetails.trueaccno,
        status: this.userDetails.status,
        zean: this.userDetails.zean,
        zean_percent: this.userDetails.zean_percent,
        line_id: this.userDetails.line_id,
      }
      // console.log(params)
      this.$http
        .post(
          `https://uatapi.sabaideelotto.com/api/zean/users/update/${this.userDetails.id}`,
          params,
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response)
          this.show = false
          this.Success('แก้ไขข้อมูลลูกค้าเรียบร้อย')
          this.Getuserdetail()
        })
        .catch(error => console.log(error))
    },
    Getuserdetail() {
      this.$http
        .get(
          `https://uatapi.sabaideelotto.com/api/zean/users/showByusername/${this.$route.params.id}`,
        )
        .then(response => {
          this.userDetails = response.data
          if (this.userDetails.zean === 1) {
            this.userDetails.zean = true
          } else {
            this.userDetails.zean = false
          }
          // console.log(this.userDetails)
        })
        .catch(error => console.log(error))
    },
    canclePro(turn) {
      this.$swal({
        title: '<h3 style="color: #000">Are you sure?</h3>',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancle it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            // eslint-disable-next-line no-underscore-dangle
            .get(
              // eslint-disable-next-line no-underscore-dangle
              `https://uatapi.sabaideelotto.com/api/zean/history/canclepro/${turn._id}`,
            )
            .then(response => {
              this.getPro()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #000">Cancled!</h3>',
                  text: 'Your Promotion has been Cancled.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => this.SwalError(error.response.data.message))
        } else {
          this.$swal(
            '<h3 style="color: #000">Cancelled</h3>',
            'not been cancled !',
            'error',
          )
        }
      })
    },
    async getPro() {
      await this.$http
        .get(
          `https://uatapi.sabaideelotto.com/api/zean/history/getturnpro/${this.$route.params.id}`,
        )
        .then(response => {
          // console.log(response.data.turnover)
          this.show = false
          this.turn = response.data.turnover
          this.turnhis = response.data.turnoverall
          this.onFilteredPro(response.data.turnoverall)
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getDepWit() {
      this.$http
        .get(
          `https://uatapi.sabaideelotto.com/api/zean/history/depwithlist/${this.$route.params.id}`,
        )
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.depositSeries[0].data = response.data.ddata
          this.withdrawSeries[0].data = response.data.wdata
          this.datalist = response.data
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFilteredPro(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.itemspro = filteredItems
      this.totalRowspro = filteredItems.length
      this.totalpro = filteredItems.total
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.DataDep
      this.items2 = filteredItems.DataWith
      this.totalRows = filteredItems.DataDep.length
      this.totalRows2 = filteredItems.DataWith.length
      // this.userData = filteredItems.userData
      // this.withamount = filteredItems.withamount
      // this.depamount = filteredItems.depamount
      this.total = filteredItems.total
      // this.seriesDep = [filteredItems.depamount]
      // this.seriesWith = [filteredItems.withamount]
      this.seriesTotal = [filteredItems.total]
      // console.log(this.userData)
    },
    // Get_userlist() {
    //   this.$http
    //     .get('/api/user/list')
    //     .then(response => {
    //       this.onFiltered(response.data.message)
    //     })
    //     .catch(error => console.log(error))
    // },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
label {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: black !important;
}
.card-warning {
  background-color: #ffb108;
  box-shadow: rgba(71, 71, 71, 0.589) 0 2px 10px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
